import React, { Suspense, useEffect } from "react";
import CustomRoutes from "../routes/Routes";
import Loader from "../components/Loader/Loader";
import "./App.scss";
import { BrowserRouter } from "react-router-dom";
import history from "../routes/History";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

export const App = () => {
  // App contains routes and also wrapped with snackbar and intl for localization
  toastr.options = {
    closeButton: false,
    progressBar: false,
    positionClass: "toast-top-right",
    fadeIn: 300,
    fadeOut: 1000,
    showDuration: "300",
    hideDuration: "1000",
    timeOut: "5000",
    extendedTimeOut: "1000",
    showEasing: "swing",
    hideEasing: "linear",
  };

  return (
    <BrowserRouter location={history.location} navigator={history}>
      <Suspense fallback={<Loader />}>
        <CustomRoutes />
      </Suspense>
    </BrowserRouter>
  );
};
