import React from "react";
import "./Loader.scss";

const Loader = () => {
  return (
    <div className="loader-wrapper">
      <div className="spinnerContainer d-flex justify-content-center align-items-center h-100">
        <div className="spinner-border text-jum" role="status"></div>
      </div>
    </div>
  );
};

export default Loader;
