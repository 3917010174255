import React from "react";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { StylesProvider } from "@material-ui/styles";
import {App} from "./containers/App"
function ThemeApp() {



  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#7c6f55",
      },
      secondary: {
        main: "#7c6f55",
      },
    },
  });
  return (
    <StylesProvider>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </StylesProvider>
  );
}

export default ThemeApp;
