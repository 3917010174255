import React from "react";

export const Login = React.lazy(() => import("../components/auth/Login"));
export const NotFound = React.lazy(() =>
  import("../components/NotFound/NotFound")
);
export const Home = React.lazy(() => import("../pages/Home/Home"));
export const WaiterScreen = React.lazy(() =>
  import("../pages/WaiterScreen/WaiterScreen")
);

export const SignUp = React.lazy(() => import("../components/auth/Register"));
export const AuthLayout = React.lazy(() => import("../components/auth"));
export const AppLayout = React.lazy(() =>
  import("../components/layouts/AppLayout")
);
