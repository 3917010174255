import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Loader from "../components/Loader/Loader";
import * as LazyComponent from "../utils/LazyLoaded";
import PrivateRoutes from "../utils/PrivateRoutes";
import { ROUTES } from "./AppRoutes";

const CustomRoutes = (props) => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {/* <Route element={<PrivateRoutes />}> */}
        <Route element={<LazyComponent.AppLayout />}>
          <Route
            path={ROUTES.HOME}
            element={<LazyComponent.Home title="Select Restaurant" />}
          />
          <Route
            path={ROUTES.WAITER_SCREEN}
            element={<LazyComponent.WaiterScreen title="Waiter Screen" />}
          />
          <Route path="*" element={<LazyComponent.NotFound />} />
        </Route>
        {/* </Route> */}
        <Route
          exact
          path={ROUTES.BASE_URL}
          element={<Navigate to={`${ROUTES.SIGN_IN}`} />}
        />
        <Route element={<LazyComponent.AuthLayout />}>
          <Route
            path={`${ROUTES.SIGN_IN}`}
            element={<LazyComponent.Login title="Sign In" />}
          />
          <Route
            path={`${ROUTES.SIGN_UP}`}
            element={<LazyComponent.SignUp title="Sign Up" />}
          />
        </Route>
        {/* <Route path="*" element={<LazyComponent.NotFound />} /> */}
      </Routes>
    </Suspense>
  );
};

export default CustomRoutes;
